import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layoutEn"
import SEO from "../components/layout/seo"
import Img from "gatsby-image"
import "../styles/post.css"
import "../styles/notice.css"

export default function Press({ data }) {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SEO title="M2MTech" />
      {/* <div className="noticeWrap">
        <h3>Notice</h3>
        <table>
          <thead>
            <th> </th>
            <th width="70%">title</th>
            <th className="tableDisappear">created by</th>
            <th>created at</th>
          </thead>
          <tbody>
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <tr key={node.fields.slug}>
                  <td>notice</td>
                  <td width="70%">
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      <span className="textOverflow">{title}</span>
                    </Link>
                  </td>
                  <td className="tableDisappear">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </td>
                  <td>{node.frontmatter.date}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div> */}
      <div className="articleUl">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          //const postImg = node.frontmatter.image.childImageSharp.fluid
          return (
            <article key={node.fields.slug}>
              <header>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  <p className="postListImg">
                    <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                  </p>
                  <p className="postListTitle">{title}</p>
                </Link>
                <p className="postListType">{node.frontmatter.type}</p>
                <p className="postListDate">{node.frontmatter.date}</p>
                <p
                  className="postListWriter"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </header>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { lang: { eq: "en" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            type
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
